import React, { useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import Modal from 'react-modal';
import { FiSmartphone, FiArrowLeft, FiUser } from 'react-icons/fi';
import { uuid } from 'uuidv4';
import { useHistory } from 'react-router-dom';
import { Button, LoadingPage } from 'ui-kit-takeat';
import ReactPixel from 'react-facebook-pixel';
import { useCart } from '../../context/cart';
import { useAuth } from '../../context/auth';
import MenuItemList from './MenuItemList';

import {
  Container,
  Content,
  MenuContent,
  MenuItem,
  Footer,
  HeaderAction,
  ButtonDeletePhone,
  CartDescription,
  UserDescription,
  RouterDomLink,
  ItemListHeader,
  ConfirmButton,
  ModalFooter,
  ModalTitle,
  UserInfo,
} from './styles';
import { useOrder } from '../../context/order';
import api from '../../services/api';
import { courtesyRestaurants } from '../../utils/constants';

const Cart = () => {
  const {
    setUserPhone,
    userPhone,
    setUserName,
    userName,
    restaurantId,
    restaurantIsOpen,
    restaurantUseCommand,
    formatValue,
    tableType,
    userLogin,
    sessionKey,
    restaurantPixelId,
    restaurantMetaToken,
    subscriptionIdPixel,
    requireTableNumber,
  } = useAuth();
  const isCourtesy = courtesyRestaurants.includes(restaurantId);
  const { cart, addCartToOrder, closeCart, cartPixel } = useCart();
  const { setOrdersTotalValue } = useOrder();
  const [makingOrder, setMakingOrder] = useState(false);
  const [modalPhrase, setModalPhrase] = useState('');

  const [userNameState, setUserNameState] = useState(() => {
    const usernameStorage = localStorage.getItem('@garcomdigital:userName');

    if (usernameStorage) {
      return usernameStorage;
    }

    return '';
  });
  const [phone, setCelphone] = useState(() => {
    const authStorage = localStorage.getItem('@garcomdigital:userPhone');

    if (authStorage) {
      return authStorage;
    }

    return '';
  });

  const [commandTableNumber, setCommandTableNumber] = useState('');

  const [modalIsOpen, setIsOpen] = useState(false);
  const history = useHistory();
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      width: '90%',
      maxWidth: '390px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '7px',
    },
  };

  function changeName() {
    setUserName('');
    setUserNameState('');
  }

  function openModal(phrase) {
    setModalPhrase(phrase);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const totalProductPrice = cart.reduce((TotalAccumulator, order) => {
    console.log('order', order);
    const totalCategories = order.complement_categories.reduce(
      (categoryAccumulator, category) => {
        if (category.more_expensive_only) {
          let totalComplements = 0;
          category.complements.forEach(complement => {
            if (
              totalComplements <
              parseFloat(complement.price) *
              (parseInt(complement.amount) >= 1 ? 1 : 0)
            ) {
              totalComplements =
                parseFloat(complement.price) *
                (parseInt(complement.amount) >= 1 ? 1 : 0);
            }
          });
          return categoryAccumulator + totalComplements;
        }
        if (category.use_average) {
          const amountAverage = category.complements.reduce(
            (accum, curr) => accum + curr.amount,
            0,
          );

          const totalPriceAverage =
            category.complements
              .map(item => item)
              .reduce(
                (acum, curr) =>
                  acum + (curr.delivery_price || curr.price) * curr.amount,
                0,
              ) / amountAverage;

          return totalPriceAverage + categoryAccumulator;
        }
        const totalComplements = category.complements.reduce(
          (complementAccumulator, complement) => {
            return complementAccumulator + complement.price * complement.amount;
          },
          0,
        );

        return (
          categoryAccumulator +
          totalComplements +
          (order.is_combo
            ? parseFloat(category.cheapest) * category.minimum
            : 0)
        );
      },
      0,
    );

    if (order.weight) {
      setOrdersTotalValue(
        TotalAccumulator +
        (parseFloat(totalCategories) +
          parseFloat(order.price * order.weight)) *
        order.amount,
      );

      return (
        TotalAccumulator +
        (parseFloat(totalCategories) + parseFloat(order.price * order.weight)) *
        order.amount
      );
    }

    setOrdersTotalValue(
      TotalAccumulator +
      (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount,
    );
    return (
      TotalAccumulator +
      (parseFloat(totalCategories) + parseFloat(order.price)) * order.amount
    );
  }, 0);

  function handleChangeCelphone(e) {
    setCelphone(e.target.value);
  }

  function handleChangeUsername(e) {
    setUserNameState(e.target.value);
  }

  function handleChangeCommandTableNumber(e) {
    setCommandTableNumber(e.target.value);
  }

  async function handleMakeAOrder() {
    if (isCourtesy) {
      if (phone.length < 15) {
        openModal('Favor preencher o celular corretamente.');
      } else if (cart.length > 0) {
        setUserPhone(phone);
        setUserName(userNameState);
        setMakingOrder(true);
        addCartToOrder(
          phone,
          userNameState,
          'courtesy',
          'prepaid',
          null,
          closeCart,
        );
      } else {
        openModal(
          'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
        );
      }
    } else if (tableType === 'prepaid') {
      if (phone.length < 15) {
        openModal('Favor preencher o celular corretamente.');
      } else if (cart.length > 0) {
        setUserPhone(phone);
        setUserName(userNameState);
        if (!sessionKey) {
          userLogin(phone, userNameState);
        }
        history.push('/app/prepaid/payment-methods');
      } else {
        openModal(
          'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
        );
      }
    } else if (restaurantUseCommand.toString() === 'true') {
      if (requireTableNumber && commandTableNumber.length < 1) {
        openModal('Informar o número ou local da mesa!');
        return;
      }
      if (phone.length < 15) {
        openModal('Favor preencher os dados corretamente.');
      } else if (cart.length > 0) {
        setMakingOrder(true);
        setUserPhone(phone);
        setUserName(userNameState);
        addCartToOrder(
          phone,
          userNameState,
          null,
          'pdv',
          commandTableNumber.toString(),
          closeCart,
        );
        // closeCart();
      } else {
        openModal(
          'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
        );
      }
    } else if (phone.length < 15) {
      openModal('Favor preencher o celular corretamente.');
    } else if (cart.length > 0) {
      setMakingOrder(true);
      setUserPhone(phone);
      setUserName(userNameState);
      addCartToOrder(phone, userNameState, null, 'pdv', null, closeCart);
    } else {
      openModal(
        'O carrinho está vazio, favor adicionar produtos para efetuar o pedido',
      );
    }
  }

  // useEffect(() => {
  //   if(restaurantUseCommand.toString() === 'true' && commandTableNumber === ''){
  //     document.getElementById('make_order_button').disabled = true;
  //   }else{
  //     document.getElementById('make_order_button').disabled = false;
  //   }
  // }, [restaurantUseCommand, commandTableNumber]);

  // pixel_fb
  const aleatoryId = uuid();

  const handleSendDataToPixel = useCallback(
    (items, ids) => {
      ReactPixel.fbq(
        'track',
        'InitiateCheckout',
        {
          content_ids: ids,
          currency: 'BRL',
          contents: items,
          value: totalProductPrice,
        },
        {
          eventID: aleatoryId,
        },
      );
    },
    [totalProductPrice],
  );

  const handleSendDataToApiConversion = useCallback(
    (items, ids) => {
      api.post(
        `https://graph.facebook.com/v19.0/${restaurantPixelId}/events?=${restaurantMetaToken}`,
        {
          pixel_id: restaurantPixelId,
          access_token: restaurantMetaToken,
          data: [
            {
              event_name: 'InitiateCheckout',
              event_time: Math.floor(Date.now() / 1000),
              event_id: aleatoryId,
              action_source: 'website',
              user_data: {
                client_user_agent: navigator.userAgent,
                subscription_id: subscriptionIdPixel,
              },
              custom_data: {
                content_ids: ids,
                currency: 'BRL',
                contents: items,
                value: totalProductPrice,
              },
            },
          ],
          partner_agent: 'Takeat',
        },
      );
    },
    [
      totalProductPrice,
      restaurantMetaToken,
      restaurantPixelId,
      subscriptionIdPixel,
    ],
  );

  useEffect(() => {
    const items = cartPixel.map(item => {
      return JSON.stringify({
        id: `${item.name}`,
        quantity: `${item.amount}`,
      });
    });
    const ids = cartPixel.map(item => item.id);

    if (restaurantPixelId) {
      handleSendDataToPixel(items, ids);
    }

    if (restaurantPixelId && restaurantMetaToken) {
      handleSendDataToApiConversion(items, ids);
    }
  }, [
    restaurantPixelId,
    handleSendDataToPixel,
    handleSendDataToApiConversion,
    restaurantMetaToken,
    totalProductPrice,
  ]);

  return makingOrder ? (
    <LoadingPage duration={3} text="Fazendo seu pedido..." />
  ) : (
    <Container>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Descrição do Item"
      >
        <ModalTitle>
          <p>{modalPhrase}</p>
        </ModalTitle>
        <ModalFooter>
          <ConfirmButton onClick={closeModal}>Ok, entendi</ConfirmButton>
        </ModalFooter>
      </Modal>
      <Content>
        <HeaderAction>
          <header>
            <RouterDomLink to={`/menu/${restaurantId}`}>
              <FiArrowLeft />
              Continuar comprando
            </RouterDomLink>
            <h1>Carrinho</h1>
          </header>
          {userPhone ? (
            <UserDescription>
              <UserInfo>
                <div>
                  <FiSmartphone />
                  <p>{userPhone}</p>
                </div>

                {userName ? (
                  <div>
                    <FiUser />
                    <p>{userName}</p>
                  </div>
                ) : (
                  <InputMask
                    placeholder="Nome (Opcional)"
                    value={userNameState}
                    onChange={e => handleChangeUsername(e)}
                    style={{ marginTop: 8, width: 200 }}
                  />
                )}
                {restaurantUseCommand.toString() === 'true' && (
                  <InputMask
                    placeholder={
                      !requireTableNumber
                        ? 'Nº da Mesa'
                        : 'Nº da Mesa (Obrigatório)'
                    }
                    value={commandTableNumber}
                    onChange={e => handleChangeCommandTableNumber(e)}
                    style={{ marginTop: 8, width: 200 }}
                    maxLength={20}
                  />
                )}
              </UserInfo>

              <ButtonDeletePhone type="button" onClick={changeName}>
                {' '}
                Alterar nome
              </ButtonDeletePhone>
            </UserDescription>
          ) : (
            <>
              <CartDescription>
                <strong>Informações</strong>

                <p>
                  Insira seus dados abaixo e participe de nosso descontos
                  exclusivos
                </p>
              </CartDescription>
              <InputMask
                inputMode="tel"
                mask="(99) 99999-9999"
                maskPlaceholder={null}
                placeholder="(xx) xxxxx-xxxx"
                value={phone}
                onChange={e => handleChangeCelphone(e)}
                style={{ marginTop: 8, fontFamily: 'Poppins' }}
              />
              <InputMask
                placeholder="Nome (Opcional)"
                value={userNameState}
                onChange={e => handleChangeUsername(e)}
                style={{ marginTop: 8, fontFamily: 'Poppins' }}
              />
              {restaurantUseCommand.toString() === 'true' && (
                <InputMask
                  placeholder={
                    !requireTableNumber
                      ? 'Nº da Mesa'
                      : 'Nº da Mesa (Obrigatório)'
                  }
                  value={commandTableNumber}
                  onChange={e => handleChangeCommandTableNumber(e)}
                  style={{ marginTop: 8, width: 200 }}
                  maxLength={20}
                />
              )}
            </>
          )}
        </HeaderAction>
        <ItemListHeader>
          <div>
            <p>Item</p>
          </div>
        </ItemListHeader>
        <MenuContent>
          <MenuItem>
            {cart.map(item => (
              <MenuItemList item={item} key={uuid()} />
            ))}
          </MenuItem>
        </MenuContent>
        <Footer>
          <div>
            {restaurantIsOpen.toString() === 'false' ? (
              <button
                type="button"
                className="btn-primary"
                onClick={handleMakeAOrder}
              >
                Restaurante Fechado
              </button>
            ) : (
              <Button
                type="button"
                // id="make_order_button"
                onClick={handleMakeAOrder}
                title={`Fazer Pedido ${courtesyRestaurants.includes(restaurantId)
                  ? ''
                  : formatValue(Number(totalProductPrice))
                  }`}
                buttonColor='#c8131b'
              />


            )}
          </div>
        </Footer>
      </Content>
    </Container>
  );
};

export default Cart;
